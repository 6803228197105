.projects-scroller {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform-style: preserve-3d;
    will-change: transform;
}
.projects {
    background-color: $color-base;
    padding-top: 10vh;
    padding-bottom: 15vh;
    .projects-title {
        margin: auto;
        max-width: 80vw;
        p {
            @include typo($font-second, 56px, #fff, 400);
        }
    }
    .last-wrp {
        margin: auto;
        max-width: 80vw;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        .project {
            width: 49%;
            &:last-child {
                margin-top: 35vh;
            }
            .link {
                display: block;
                padding: 10px 0;
                cursor: pointer;
                text-align: center;
                background-color: #fff;
                @include typo($font-base, 19px, #000, 400);
            }
        }
        .projects-img {
            img {
                display: block;
                width: 100%;
                height: 700px;
                object-fit: cover;
            }
        }
        .row {
            display: flex;
            border-bottom: 1px solid #fff;
            .col {
                border-right: 1px solid #fff;
                padding-top: 15px;
                padding-bottom: 25px;
                &.-p0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important; 
                    padding-bottom: 0 !important;  
                }
                &:first-child {
                    width: 65px;
                    border-left: 1px solid #fff;
                    text-align: center;
                }
                &:nth-child(2) {
                    width: auto;
                    flex: 1;
                    padding-left: 30px;
                    padding-right: 30px;
                    p {
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                &:last-child {
                    width: 145px;
                    padding-left: 15px;
                    padding-right: 15px;
                }
                span {
                    @include typo($font-base, 24px, #fff, 400);
                }
                .heading {
                    p {
                        @include typo($font-base, 18px, #fff, 700);
                    }
                }
                .text {
                    p {
                        @include typo($font-base, 14px, #fff, 400);
                    }
                }
                .block {
                    padding-bottom: 25px;
                    padding-right: 15px;
                    padding-left: 15px;
                    border-bottom: 1px solid #fff;
                    &:last-child {
                        border-bottom: none;
                        padding-top: 15px;
                    }
                }
            }
        }
    }
    .table-wrp {
        margin-top: 50px;
        .row {
            width: 100%;
            border-top: 1px solid #fff;
            &:last-child {
                border-bottom: 1px solid #fff;
            }
            .row-inner {
                display: flex;
                max-width: 80vw;
                margin: auto;
                .col {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-right: 1px solid #fff;
                    &:first-child {
                        width: 70px;
                        border-left: 1px solid #fff;
                        text-align: center;
                    }
                    &:nth-child(2) {
                        width: auto;
                        flex: 1;
                        padding-left: 75px;
                    }
                    &:nth-child(3) {
                        width: 145px;
                        padding: 0 !important;
                    }
                    &:last-child {
                        width: 145px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    span {
                        @include typo($font-base, 24px, #fff, 400);
                    }
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        padding: 20px 10px;
                        .link-txt {
                            display: inline-block;
                            position: relative;
                            z-index: 1;
                            transition: color 0.5s ease;
                            @include typo($font-base, 19px, #fff, 400);
                        }
                        .link-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #fff;
                            transition: all 0.6s ease;
                            transform: rotateY(90deg);
                            opacity: 0;
                        }
                        &:hover {
                            .link-txt {
                                color: #000;
                            }
                            .link-overlay {
                                opacity: 1;
                                transform: rotateY(0);
                            }
                        }
                    }
                    .name {
                        p {
                            max-width: 50%;
                            @include typo($font-base, 20px, #fff, 700);
                        }
                    }
                    .country {
                        p {
                            @include typo($font-base, 14px, #fff, 700);
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1367px) {
    .projects {
        .last-wrp {
            .projects-img {
                img {
                    height: 500px;
                }
            }
        }
    }
}