// Normalize Styles
@import 'node_modules/normalize.css/normalize';

//Fonts
@import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,700&subset=cyrillic-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

html {
    font-size: 16px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    outline: none;
}

body {
    overflow-x: hidden;
    &.y-scroll {
        overflow-y: scroll;
        overflow-x: hidden;
    }
    &.-hidden {
        overflow: hidden;
    }
}
img {
    max-width: 100%;
}
a,
a:hover {
    text-decoration: none;
}

html {
    &:lang(ru) {
        font-size: 14px;
    }
}

@media screen and(max-width: 1441px) {
    html {
        font-size: 15px;
    }
}

@media screen and(max-width: 1367px) {
    html {
        font-size: 15px;
    }
}

@media screen and(max-width: 480px) {
    html {
        font-size: 16px;
    }
}