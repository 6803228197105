.team-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: none;
    justify-content: center;
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);   
    }
    .close {
        background: none;
        display: block;
        margin: auto;
        margin-top: 5vh;
        margin-bottom: 3vh;
        width: 30px;
        height: 30px;
        padding: 5px;
        text-align: center;
        border: none;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .container {
        height: 100%;
        background: #fff;
        width: 635px;
        position: relative;
        text-align: center;
        .image {
            img {
                height: 265px;
            }
        }
        .name {
            margin-top: 25px;
            margin-bottom: 10px;
            p {
                @include typo($font-base, 24px, #000, 700);
            }
        }
        .position {
            p {
                @include typo($font-base, 20px, #000, 400);
            }
        }
        .info {
            p {
                @include typo($font-base, 17px, #000, 400);
                margin-top: 20px;
                padding: 0 45px;
            }
        }
    }
}
@media screen and (max-width: 1601px) {
    .team-modal {
        .container {
            .name {
                margin-top: 15px;
            }
            .info {
                p {
                    @include rem(14px);
                    margin-top: 15px;
                }
            }
        }
    }
}
@media screen and (max-width: 1367px) {
    .team-modal {
        .container {
            .image {
                img {
                    height: 200px;
                }
            }
        }
    }
}
@media screen and (max-width: 1025px) {
    .team-modal {
        .inner {
            overflow-y: auto;
            height: calc(100vh - 10vh);
            padding: 0 20px;
            padding-bottom: 30px;
            .info {
                p {
                    padding: 0;
                }
            }
        }
    }
}