.service-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: none;
    justify-content: center;
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);   
    }
    .close {
        background: none;
        display: block;
        margin: auto;
        margin-top: 25vh;
        margin-bottom: 10vh;
        width: 30px;
        height: 30px;
        padding: 5px;
        text-align: center;
        border: none;
        cursor: pointer;
    }
    .inner {
        height: 100%;
        background: #0067DD;
        width: 635px;
        position: relative;
        .heading {
            p {
                @include typo($font-base, 28px, #fff, 700);
                text-align: center;
            }
        }
        .title {
            text-align: center;
            margin-top: 10vh;
            margin-bottom: 30px;
            p {
                @include typo($font-second, 20px, #fff, 400);
            }
        }
        .text {
            p {
                padding: 0 20px;
                margin-top: 20px;
                @include typo($font-base, 16px, #fff, 400);
                text-align: center;
            }
        }
        .form {
            text-align: center;
            .input-wrp {
                position: relative;
                width: 470px;
                margin: auto;
                margin-bottom: 30px;
                input {
                    border: none;
                    border-bottom: 1px solid #DEDEDE;
                    width: 470px;
                    position: relative;
                    z-index: 1;
                    background-color: transparent;
                    @include typo($font-second, 15px, #fff, 400);
                    text-align: center;
                    &:focus {
                        & ~ label {
                            transform: translateY(-20px) scale(0.8);
                        }
                    }
                }
                label {
                    @include typo($font-second, 15px, #fff, 400);
                    position: absolute;
                    top: -5px;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    transition: transform 0.4s ease;
                    transform-origin: top;
                    will-change: transform;
                }
            }
            .button {
                background-color: transparent;
                border: 1px solid #fff;
                display: flex;
                align-items: center;
                padding: 15px 20px;
                margin: auto;
                cursor: pointer;
                span {
                    display: inline-block;
                    margin-right: 20px;
                    @include typo($font-base, 20px, #fff, 400);
                }
                path {
                    stroke: #fff;
                }
            }
        }
    }
}
@media screen and (max-width: 1601px) {
    .service-modal {
        .close {
            margin-top: 15vh;
            margin-bottom: 5vh;
        }
    }
}
@media screen and (max-width: 1367px) {
    .service-modal {
        .inner {
            .close {
                margin-top: 10vh;
            }
            .title {
                margin-top: 5vh;
            }
        }
    }
}
@media screen and (max-width: 1025px) {
    .service-modal {
        .inner {
            width: 100%;
            .form {
                .input-wrp {
                    width: 100%;
                }
            }
            .heading {
                p {
                    @include rem(20px);
                }
            }
            .text {
                p {
                    @include rem(12px);
                }
            }
        }
    }
}
@media screen and (max-width: 321px) {
    .service-modal {
        .inner {
            width: 100%;
            .close {
                margin-top: 5vh;
                margin-bottom: 20px;
            }
            .text {
                p {
                    margin-top: 15px;
                }
            }
            .form {
                .input-wrp {
                    margin-bottom: 20px;
                    input {
                        &:focus {
                            & ~ label {
                                transform: translateY(-10px) scale(0.8);
                            }
                        }
                    }
                    label {
                        font-size: 14px;
                    }
                }
                .button {
                    padding: 10px 20px;
                    span {
                        @include rem(14px);
                    }
                }
            }
        }
    }
}