.home-inner {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: auto;
    height: 100%;
    display: flex;
    padding-right: 500px;
    will-change: transform;
    transform-style: preserve-3d;
    .home-hero {
        min-width: 90vw;
        height: 100%;
        transform: translateX(279px);
        background: #fff;
        position: relative;
        transition: all 0.4s;
        display: flex;
        align-items: center;
        z-index: 1;
        &.-scrolled {
            transform: translateX(64px);
        }
        img {
            display: block;
            object-fit: cover;
            width: 95%;
            height: 95%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        .heading-wrp {
            background-color: #fff;
            width: 49vw;
            padding: 40px;
            text-align: right;
            white-space: initial;
            position: relative;
            z-index: 1;
            h1 {
                text-align: left;
                @include typo($font-second, 70px, #000, 400);
            }
        }
    }
    .home-services {
        width: auto;
        height: 100%;
        display: flex;
        background-color: #fff;
        transform: translateX(64px);
        position: relative;
        .title {
            position: absolute;
            top: 10vh;
            left: 0;
            width: 100%;
            padding-left: 50.5vw;
            p {
                @include typo($font-second, 56px, #000, 400);
            }
        }
        .left-col {
            display: flex;
            align-items: center;
            position: relative;
            width: 50vw;
            height: 100%;
            float: left;
            .left-inner {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                background-color: #fff;
                padding: 30px 40px;
                .left-heading {
                    p {
                        @include typo($font-second, 60px, #000, 400);
                    }
                }
                .left-txt {
                    p {
                        @include typo($font-base, 30px, #000, 400);
                        max-width: 655px;
                        margin: 15px 0;
                        span {
                            color: $color-red;
                            display: inline-block;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .right-col {
            display: flex;
            height: 100%;
            .right-block {
                border-left: 1px solid #E8E8E8;
                padding: 0 8px;
                padding-top: 30vh;
                width: 355px;
                &:last-child {
                    border-right: 1px solid #e8e8e8;
                }
                .right-num {
                    span {
                        @include typo($font-base, 16px, #000, 400);
                    }
                }
                .right-img {
                    img {
                        display: block;
                        width: 100%;
                        height: 315px;
                        margin-bottom: 10px;
                        object-fit: cover;
                    }
                }
                .block-name {
                    p {
                        @include typo($font-base, 24px, #000, 700);
                        text-transform: uppercase;
                    }
                }
                .right-list {
                    list-style: none;
                    padding-left: 0;
                    margin-top: 5px;
                    li {
                        padding: 15px 0;
                        border-bottom: 1px solid #E8E8E8;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        backface-visibility: hidden;
                        &:hover {
                            .list-txt {
                                color: $color-blue;
                            }
                            a {
                                transform: translateY(0);
                                opacity: 1;
                            }
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                        .list-txt {
                            @include typo($font-base, 14px, #000, 400);
                            transition: color 0.5s ease;
                        }
                        a {
                            white-space: nowrap;
                            @include typo($font-base, 14px, $color-red, 400);
                            transition: 0.5s ease;
                            opacity: 0;
                            transform: translateY(5px);
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
    .link {
        display: inline-flex;
        padding: 15px 20px;
        border: 1px solid $color-red;
        align-items: center;
        background-color: transparent;
        cursor: pointer;
        span {
            display: inline-block;
            transform: translateY(-2px);
            margin-right: 20px;
            @include typo($font-base, 20px, $color-red, 400);
        }
    }
    .projects-link {
        height: 100%;
        width: 335px;
        position: absolute;
        top: 0;
        right: -30px;
        white-space: nowrap;
        overflow: hidden;
        .inner {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
            width: 335px;
            transform: translate(0, -50%);
            display: flex;
            align-items: center;
        }
        .link-txt {
            @include typo($font-second, 16px, #fff, 400);
            display: inline-block;
            margin-left: 40px;
            margin-right: 20px;
        }
        .link-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-base;
        }
    }
}
.mobile-home {
    display: none;
}
@media screen and (max-width: 1601px) {
    .home-inner {
        .home-services {
            .title {
                top: 6vh;
            }
            .right-col {
                .right-block {
                    padding-top: 25vh;
                    .right-list {
                        li {
                            padding: 10px 0;
                            a {
                                &:lang(ru) {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                    .block-name {
                        &:lang(ru) {
                            p {
                                font-size: 1.1rem;
                            }
                        }
                    }
                }
            }
            .left-col .left-inner .left-txt {
                &:lang(ru) {
                    p {
                        font-size: 1.4rem;
                    }
                }
            }
        }
        .heading-wrp {
            &:lang(ru) {
                h1 {
                    font-size: 3.4rem;
                }
            }
        }
    }
}
@media screen and (max-width: 1441px) {
    .home-inner {
        padding-right: 600px;
    }
}
@media screen and (max-width: 1367px) {
    .home-inner {
        padding-right: 375px;
        .projects-link {
            width: 250px;
        }
        .home-services {
            .title {
                padding-left: 60.5vw;
            }
            .left-col {
                width: 60vw;
            }
            .right-col {
                .right-block {
                    padding-top: 20vh;
                    .right-img {
                        img {
                            height: 250px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1025px) {
    .home-inner {
        display: none;
    }
    .mobile-home {
        display: block;
        .link {
            margin-top: 30px;
            display: inline-flex;
            padding: 10px 15px;
            border: 1px solid $color-red;
            align-items: center;
            background-color: transparent;
            cursor: pointer;
            span {
                display: inline-block;
                margin-right: 20px;
                @include typo($font-base, 13px, $color-red, 400);
            }
        }
        .hero {
            height: 100vh;
            img {
                height: 80vh;
                width: 100%;
                object-fit: cover;
                display: block; 
            }
            .hero-inner {
                background: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                width: 95%;
                padding: 30px 15px;
                padding-bottom: 15px;
                text-align: right;
                .heading {
                    text-align: left;
                    h2 {
                        @include typo($font-second, 20px, #000, 400);
                        max-width: 75%;
                    }
                }
            }
            .scroll {
                text-align: center;
                padding-top: 25px;
                span {
                    display: block;
                    @include typo($font-second, 12px, #000, 300);
                    margin-bottom: 20px;
                }
            }
        }
        .who {
            height: 80vh;
            position: relative;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                display: block; 
            }
            .inner {
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                width: 95%;
                background: #fff;
                padding: 30px 15px;
                padding-top: 65px;
                .heading {
                    p {
                        @include typo($font-second, 20px, #000, 400);
                    }
                }
                .txt {
                    p {
                        @include typo($font-base, 16px, #000, 400);
                        max-width: 655px;
                        margin: 15px 0;
                        span {
                            color: $color-red;
                            display: inline-block;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .services {
            padding: 10vh 10px;
            padding-bottom: 0;
            .title {
                padding-left: 10px;
                p {
                    @include typo($font-second, 20px, #000, 400);
                }
            }
            .block {
                margin: 30px 0;
                padding-bottom: 30px;
                .image {
                    img {
                        width: 100%;
                        height: 150px;
                        object-fit: cover;
                        display: block;
                    }
                }
                .name {
                    padding: 0 10px;
                    margin-top: 25px;
                    p {
                        @include typo($font-base, 16px, #000, 700);
                        text-transform: uppercase;
                    }
                }
                .list {
                    padding: 0 10px;
                    margin-top: 20px;
                    li {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid #E8E8E8;
                        padding: 15px 0;
                        &:last-child {
                            border-bottom: none;
                        }
                        span, a {
                            @include typo($font-base, 14px, #000, 400);
                        }
                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .projects {
            background: $color-base;
            padding-top: 70px;
            padding-bottom: 10vh;
            padding-left: 20px;
            padding-right: 20px;
            .arrow {
                text-align: center;
                span {
                    margin-bottom: 20px;
                    @include typo($font-second, 12px ,#fff, 400);
                    display: block;
                }
                svg {
                    width: 50px;
                    height: 50px;
                    transform: rotate(90deg);
                }
            }
            .block {
                margin-top: 65px;
                a {
                    display: block;
                }
                .image {
                    margin-bottom: 15px;
                    img {
                        width: 100%;
                        height: 200px;
                        object-fit: cover;
                        display: block;
                    }
                }
                .name {
                    @include typo($font-base, 14px, #fff, 700);
                    span {
                        display: block;
                        margin-bottom: 5px;
                    }
                }
                .project-link {
                    display: flex;
                    border: 1px solid #fff;
                    padding: 10px 15px;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 20px;
                    span {
                        @include typo($font-base, 13px, #fff, 400);
                    }
                }
            }
        }
    }
}