.main-nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 280px;
    transition: width 0.4s;
    height: 100%;
    padding-top: 70px;
    background-color: #fff;
    border-right: 1px solid #E8E8E8;
    &.-closed {
        width: 65px;
        .list {
            opacity: 0;
        }
        .burger {
            opacity: 1;
            visibility: visible;
        }
    }
    .logo {
        display: flex;
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        span {
            text-transform: uppercase;
            word-wrap: break-word;
            width: 12px;
            display: inline-block;
            text-align: center;
            line-height: 1;
            @include typo($font-second, 17px, #000, 700);
            &:first-child {
                color: $color-blue;
                margin-right: 5px;
            }
        }
    }
    .burger {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.4s;
        opacity: 0;
        visibility: hidden;
        padding: 0;
        background-color: #fff;
        border: none;
        width: 18px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        span {
            display: inline-block;
            width: 2px;
            height: 30px;
            background-color: #000;
        }
    }
    .list {
        padding-left: 0;
        list-style: none;
        transition: opacity 0.4s;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        li {
            padding: 15px 0;
            padding-right: 55px;
            border-top: 1px solid #E8E8E8;
            a {
                @include typo($font-base, 16px, #000, 400);
                display: inline-block;
                white-space: nowrap;
                transition: color .6s;
                &:hover {
                    color: $color-blue;
                }
            }
            &:first-child {
                border-top: none;
            }
            &:last-child {
                padding-top: 50px;
                border-top: none;
                a {
                    display: inline-block;
                    margin-right: 30px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
.mobile-nav {
    display: none;
}
@media screen and (max-width: 1367px) {
    .main-nav { 
        .logo {
            top: 20px;
        }
        .list {
            li {
                padding: 10px 0;
                padding-right: 50px;
            }
        }
    }
}
@media screen and (max-width: 1025px) {
    .main-nav {
        display: none;
    }
    .mobile-nav {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        background: #fff;
        width: 100%;
        height: 62px;
        overflow: hidden;
        .top {
            display: flex;
            justify-content: space-between;
            padding: 15px 20px;
            .logo {
                p {
                    @include typo($font-second, 14px, #000000, 700);
                    text-transform: uppercase;
                    span {
                        display: block;
                        color: $color-blue;
                    }
                }
            }
            .burger {
                background-color: transparent;
                border: none;
                padding: 0;
                width: 23px;
                &.-open {
                    span {
                        &:first-child {
                            transform: rotate(45deg);
                        }
                        &:last-child {
                            transform: rotate(-45deg);
                        }
                        &:nth-child(2) {
                            transform: translateX(-5px);
                            opacity: 0;
                        }
                    }
                }
                span {
                    display: block;
                    width: 100%;
                    height: 2px;
                    background-color: #000;
                    margin-bottom: 6px;
                    transition: all 0.4s ease;
                    transform-origin: left;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .list {
            width: 100%;
            height: calc(100vh - 62px);
            padding-left: 0;
            list-style: none;
            padding: 0 20px;
            padding-top: 20vh;
            li {
                border-top: 1px solid #E8E8E8;
                a {
                    @include typo($font-base, 16px, #000, 400);
                    display: inline-block;
                    padding: 15px 0;
                    width: 100%;
                    white-space: nowrap;
                    transition: color .6s;
                    &:hover {
                        color: $color-blue;
                    }
                }
                &:first-child {
                    border-top: none;
                }
                &:last-child {
                    padding-top: 50px;
                    border-top: none;
                    a {
                        display: inline-block;
                        margin-right: 30px;
                        width: initial;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}