.team {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .team-bg {
        @include typo($font-second, 244px, #F0F0F0, 700);
        position: absolute;
        top: -20px;
        left: 0;
        width: 100%;
        text-transform: uppercase;
        z-index: -1;
        padding-left: 20vw;
    }
    .team-top {
        padding-top: 60px;
        .team-heading {
            p {
                @include typo($font-second, 56px, #000, 400);
                margin-bottom: 15px;
            }
        }
        .team-txt {
            p {
                @include typo($font-base, 16px, #000, 400);
                max-width: 775px;
                margin-bottom: 40px;
            }
        }
    }
    .team-row {
        display: flex;
        height: 100vh;
        .team-col {
            min-width: 335px;
            margin: 0 40px;
            cursor: pointer;
            &.-small {
                min-width: 270px;
            }
            &:nth-child(even) {
                margin-top: 12vh;
            }
            // &:first-child {
            //     min-width: 650px;
            //     position: relative;
            //     .team-img {
            //         height: auto;
            //         img {
            //             position: static;
            //             transform: none;
            //         }
            //     }
            //     .team-info {
            //         position: absolute;
            //         top: 60%;
            //         left: 0;
            //         transform: translateY(-50%);
            //         width: 100%;
            //         padding-left: 7vw;
            //     }
            // }
            .team-num {
                @include typo($font-base, 20px, #000, 400);
            }
            .team-img {
                width: 100%;
                overflow: hidden;
                position: relative;
                img {
                    display: block;
                    transition: filter 0.6s ease;
                    filter: grayscale(1);
                    &:hover {
                        filter: grayscale(0);
                    }
                }
            }
            .team-info {
                padding-top: 20px;
                .name {
                    p {
                        @include typo($font-base, 23px, #000, 700);
                    }
                }
                .info {
                    p {
                        @include typo($font-base, 20px, #000, 400);
                    }
                }
            }
        }
    }
    .team-scroller {
        padding-left: 10vw;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        will-change: transform;
        transform-style: preserve-3d;
        padding-right: 25vw;
    }
    .partners {
        min-width: 60vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .partners-inner {
            width: 100%;
            padding-left: 7vw;
        }
        .partners-title {
            margin-bottom: 10vh;
            p {
                @include typo($font-base, 56px, #000, 400);
            }
        }
        .partners-row {
            display: flex;
            max-width: 90%;
            justify-content: space-between;
            align-items: center;
            margin: 30px;
            img {
                display: inline-block;
                filter: grayscale(100%);
                transition: filter 0.6s ease;
                &:hover {
                    filter: grayscale(0);
                }
            }
            .-large {
                img {
                    width: 200px;
                }
            }
        }
    }
    .contacts-link {
        height: 100vh;
        width: 335px;
        position: absolute;
        top: 0;
        right: -30px;
        white-space: nowrap;
        overflow: hidden;
        .inner {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
            width: 335px;
            transform: translate(0, -50%);
            display: flex;
            align-items: center;
        }
        .link-txt {
            @include typo($font-second, 16px, #fff, 400);
            display: inline-block;
            margin-left: 40px;
            margin-right: 20px;
        }
        .link-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-base;
        }
    }
}
.mobile-team {
    display: none;
}
@media screen and (max-width: 1601px) {
    .team {
        .team-top {
            padding-top: 40px;
        }
        .team-row .team-col:first-child .team-info {
            top: 50%;
        }
        .team-row .team-col {
            min-width: 250px;
            &.-small {
                min-width: 220px;
            }
        }
        // .team-row .team-col .team-img {
        //     height: 345px;
        // }
        .partners {
            min-width: 80vw;
        }
    }
}
@media screen and (max-width: 1367px) {
    .team .team-row .team-col {
        min-width: 200px;
    }
    .team .team-row .team-col.-small {
        min-width: 180px;
    }
    .team .partners .partners-row img {
        max-width: 70%;
    }
    .team .partners .partners-row .-large img {
        max-width: 80%;
    }
    .team .team-row .team-col .team-info .info p {
        font-size: 1rem;
    }
    .team .team-row .team-col .team-info {
        padding-top: 15px;
    }
    .team .team-row .team-col .team-info .name p {
        font-size: 1.3rem;
    }
}
@media screen and (max-width: 1025px) {
    .team {
        display: none;
    }
    .mobile-team {
        display: block;
        padding-bottom: 10vh;
        margin-top: 62px;
        .title {
            p {
                @include typo($font-second, 20px, #000, 400);
                margin-bottom: 30px;
            }
        }
        .top {
            position: relative;
            padding: 60px 20px;
            .bg-heading {
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                padding: 0 10px;
                p {
                    text-transform: uppercase;
                    @include typo($font-second, 108px, #F0F0F0, 700);
                    font-size: 24vw;
                    &:lang(ru) {
                        font-size: 17.5vw;
                    }
                }
            }
            .text {
                p {
                    @include typo($font-base, 14px, #000, 400);
                }
            }
        }
        .row {
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            margin-top: 30px;
            .col {
                width: 48%;
                .image {
                    img {
                        width: 100%;
                        height: 200px;
                        object-fit: cover;
                        object-position: top;
                    }
                }
                .name {
                    margin-top: 10px;
                    p {
                        @include typo($font-base, 14px, #000, 700);
                    }
                }
                .info {
                    p {
                        @include typo($font-base, 12px, #000, 400);
                    }
                }
            }
        }
        .partners {
            margin-top: 15vh;
            .title {
                padding: 0 20px;
                margin-bottom: 10vh;
            }
            .col {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 60%;
                }
            }
        }
    }
}