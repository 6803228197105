.contacts {
    background: $color-base;
    width: 100vw;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    .contacts-row {
        display: flex;
        height: 90%;
        .contacts-title {
            width: 35%;
            padding-left: 50px;
        }
        .contacts-right {
            width: 65%;
            position: relative;
            .contacts-inner {
                position: absolute;
                background-color: $color-base;
                top: 50%;
                left: -1px;
                transform: translateY(-50%);
                display: flex;
                padding: 10vh 60px;
                .contacts-col {
                    margin-right: 6vw;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }
        }
    }
    .contacts-copyr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 10%;
        padding: 0 5vw;
        p {
            @include typo($font-base, 14px, #fff, 400);
            &:last-child {
                font-family: "Roboto";
                font-weight: 400;
                a {
                    font-weight: 300;
                    color: white;
                    b {
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .contacts-title {
        display: flex;
        align-items: center;
        p {
            @include typo($font-second, 167px, #fff, 700);
            text-transform: uppercase;
            line-height: 0.8;
        }
    }
    .contacts-heading {
        p {
            @include typo($font-base, 24px, #fff, 700);
            margin-bottom: 35px;
            min-height: 30px;
        }
    }
    .contacts-info {
        * {
            @include typo($font-base, 20px, #fff, 400);
            max-width: 260px;
        }
        a {
            display: block;
        }
    }
    .contacts-btn {
        background-color: transparent;
        border: 1px solid #fff;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        margin-top: 50px;
        cursor: pointer;
        span {
            @include typo($font-base, 20px, #fff, 400);
            display: inline-block;
            margin-right: 35px;
        }
    }
}
@media screen and (max-width: 1601px) {
    .contacts {
        .contacts-title {
            p {
                font-size: 7.5rem;
            }
        }
    }
}
@media screen and (max-width: 1441px) {
    .contacts {
        .contacts-row {
            .contacts-right {
                .contacts-inner {
                    .contacts-col {
                        margin-right: 3vw;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1281px) {
    .contacts {
        .contacts-row {
            .contacts-right {
                .contacts-inner {
                    padding: 10vh 40px;
                }
            }
        }
    }
}
@media screen and (max-width: 1025px) {
    .contacts {
        height: auto;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-top: 62px;
        .contacts-row {
            height: auto;
            flex-wrap: wrap;
            .contacts-title {
                width: 100%;
                padding: 0 20px;
                p {
                    @include rem(60px);
                    display: inline;
                }
            }
            .contacts-right {
                width: 100%;
                .contacts-inner {
                    position: static;
                    transform: none;
                    flex-wrap: wrap;
                    padding: 40px 20px;
                    .contacts-col {
                        width: 100%;
                        margin-bottom: 35px;
                        margin-right: 0;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                img {
                    display: none;
                }
            }
        }
        .contacts-heading p {
            margin-bottom: 25px;
        }
        .contacts-btn {
            margin-top: 25px;
        }
        .contacts-copyr {
            flex-wrap: wrap;
            p {
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }
}