.feedback {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: none;
    justify-content: center;
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(#0E3057, 0.97);   
    }
    .close {
        background: none;
        display: block;
        width: 30px;
        height: 30px;
        padding: 5px;
        position: absolute;
        top: 15vh;
        right: -50px;
        border: none;
        cursor: pointer;
    }
    .inner {
        height: 100%;
        background: #fff;
        width: 635px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .sub {
            text-align: center;
            p {
                @include typo($font-second, 19px, $color-base, 400);
            }
        }
        .title {
            text-align: center;
            margin-top: 10vh;
            margin-bottom: 10vh;
            p {
                @include typo($font-second, 36px, #000, 400);
            }
        }
        .form {
            .input-wrp {
                position: relative;
                margin-bottom: 30px;
                input {
                    border: none;
                    border-bottom: 1px solid #DEDEDE;
                    width: 470px;
                    position: relative;
                    z-index: 1;
                    background-color: transparent;
                    @include typo($font-second, 15px, #000, 400);
                    &:focus {
                        & ~ label {
                            transform: translateY(-20px) scale(0.8);
                        }
                    }
                }
                label {
                    @include typo($font-second, 15px, #2265B2, 400);
                    position: absolute;
                    top: -5px;
                    left: 0;
                    transition: transform 0.4s ease;
                    transform-origin: top left;
                    will-change: transform;
                }
            }
            .button {
                background-color: transparent;
                border: 1px solid $color-red;
                display: flex;
                align-items: center;
                padding: 15px 20px;
                margin: auto;
                span {
                    display: inline-block;
                    margin-right: 20px;
                    @include typo($font-base, 20px, $color-red, 400);
                }
            }
        }
    }
}
@media screen and (max-width: 1025px) {
    .feedback {
        .close {
            right: 30px;
            top: 30px;
            filter: invert(1);
        }
        .inner {
            width: 100%;
            .title {
                p {
                    @include rem(20px);
                }
            }
            .sub {
                p {
                    @include rem(14px);
                }
            }
            .form {
                .input-wrp {
                    input {
                        width: 90vw;
                    }
                }
                .button {
                    padding: 10px 15px;
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}