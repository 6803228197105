@function calcRem($font-size) {
    $remSize: $font-size / 16px;
    @return #{$remSize}rem;
}

@mixin rem($font-size) {
  font-size: $font-size; //Fallback in px
  font-size: calcRem($font-size);
}

@mixin typo($family, $size, $color, $weight: 400) {
  font-family: $family;
  font-size: $size;
  font-size: calcRem($size);
  color: $color;
  font-weight: $weight;
}

$font-base: "Inconsolata", sans-serif;
$font-second: "Ubuntu", sans-serif;

$color-base: #0E3057;
$color-blue: #2265B2;
$color-red: #FF0000;

html {
    &:lang(ru) {
        * {
            font-family: "Ubuntu", sans-serif !important;
        }
    }
}