.projects-inner {
    .mobile-top-block {
        display: none;
    }
    .top {
        height: 57vh;
        position: relative;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .top-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.6);
        }
        .heading {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 1225px;
            p {
                @include typo($font-base, 30px, #fff, 700);
                text-transform: uppercase;
                max-width: 70%;
                position: relative;
                &:before {
                    content: '';
                    width: 6px;
                    height: 100%;
                    background-color: #0067DD;
                    position: absolute;
                    left: -20px;
                    top: 0;
                }
            }
        }
    }
    .bot {
        margin: auto;
        max-width: 1225px;
        .bot-inner {
            display: flex;
            .bot-content {
                width: auto;
                flex: 1;
                padding-top: 10px;
                padding-bottom: 10vh;
                h6 {
                    margin-top: 20px;
                    @include typo($font-base, 20px, #000, 700);
                }
                p {
                    @include typo($font-base, 14px, #000, 400);
                    margin-top: 20px;
                    max-width: 90%;
                }
                ul {
                    margin-top: 20px;
                    padding-left: 20px;
                    li {
                        @include typo($font-base, 14px, #000, 400);
                    }
                }
            }
            .bot-right {
                width: 400px;
                .top-block {
                    background: #0067DD;
                    .top-list {
                        list-style: none;
                        padding-left: 0;
                        padding-top: 35px;
                        padding-bottom: 35px;
                        li {
                            padding: 10px 15px;
                            border-bottom: 1px solid #2C89F4;
                            &:last-child {
                                border-bottom: none;
                            }
                            span {
                                @include typo($font-base, 16px, #fff, 700);
                                opacity: 0.3;
                                display: inline-block;
                                margin-bottom: 10px;
                            }
                            p {
                                @include typo($font-base, 18px, #fff, 700);
                            }
                        }
                    }
                }
                .bot-block {
                    background: #0056B9;
                    padding-top: 25px;
                    padding-bottom: 35px;
                    .sub {
                        p {
                            @include typo($font-base, 16px, #fff, 700);
                            text-transform: uppercase;
                            position: relative;
                            padding-left: 15px;
                            margin-left: 15px;
                            &:before {
                                content: '';
                                width: 6px;
                                height: 6px;
                                background-color: #fff;
                                border-radius: 50%;
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                            }
                        }
                    }
                    .bot-nav {
                        margin-top: 30px;
                        a {
                            padding: 15px;
                            display: inline-block;
                            border-bottom: 1px solid #2C89F4;
                            &:last-child {
                                border-bottom: none;
                            }
                            span {
                                @include typo($font-base, 16px, #fff, 700);
                                opacity: 0.5;
                            }
                            p {
                                margin-top: 10px;
                                @include typo($font-base, 18px, #fff, 700);
                            }
                        }
                    }
                    .all-projects {
                        text-align: right;
                        padding: 0 15px;
                        margin-top: 30px;
                        a {
                            @include typo($font-base, 15px, #fff, 400);
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1601px) {
    .projects-inner {
        .top {
            .heading {
                max-width: 70vw;
            }
        }
        .bot {
            max-width: 70vw;
        }
    }
}
@media screen and (max-width: 1367px) {
    .projects-inner {
        .bot {
            .bot-inner {
                .bot-right {
                    width: 300px;
                }
            }
        }
    }
}
@media screen and (max-width: 1281px) {
    .projects-inner {
        .bot {
            .bot-inner {
                .bot-right {
                    width: 300px;
                }
            }
        }
    }
}
@media screen and (max-width: 1025px) {
    .projects-inner {
        margin-top: 62px;
        .top-block {
            display: none;
        }
        .top {
            height: 25vh;
            .heading {
                max-width: 100%;
                padding-left: 30px;
                padding-right: 15px;
                p {
                    @include rem(15px);
                    &:before {
                        width: 2px;
                        left: -10px;
                    }
                }
            }
        }
        .mobile-top-block {
            display: block;
            background: #0067DD;
            width: 100%;
            .top-list {
                list-style: none;
                padding-left: 0;
                padding-top: 35px;
                padding-bottom: 35px;
                li {
                    padding: 10px 15px;
                    border-bottom: 1px solid #2C89F4;
                    &:last-child {
                        border-bottom: none;
                    }
                    span {
                        @include typo($font-base, 16px, #fff, 700);
                        opacity: 0.3;
                        display: inline-block;
                        margin-bottom: 10px;
                    }
                    p {
                        @include typo($font-base, 18px, #fff, 700);
                    }
                }
            }
        }
        .bot {
            max-width: 100%;
            .bot-inner {
                flex-wrap: wrap;
                .bot-content {
                    padding: 35px 20px;
                    p {
                        max-width: 100%;
                    }
                }
                .bot-right {
                    width: 100%;
                    .bot-block {
                        .bot-nav {
                            a {
                                p {
                                    @include rem(14px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}